<template>
    <!-- Partners and Supporters start -->
    <section class="partners bg-light-gray1">
        <div id="partners" class="count-partners-sec">
            <div class="count-partners-overlay"></div>
            <div class="container">
                <div class="row text-center count-partners-content">
                    <div class="col-12 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('partners_section.title') }}
                        </h2>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 text-center pr-lg-0 md-mb-5 wow fadeInLeft">
                    <div class="price-item price-transform">
                        <div class="price-description" style="padding-bottom: 86px;">
                            <p>
                                <a href="https://www.1000daysfund.org/" target="_blank">
                                    1000 Days Fund
                                </a>
                            </p>
                            <p>
                                <a href="https://www.agunity.com" target="_blank">
                                    AgUnity
                                </a>
                            </p>
                            <p>
                                <a href="http://akarumput.com/en" target="_blank">
                                    Akarumput
                                </a>
                            </p>
                            <p>
                                <a href="https://www.acu.edu.au" target="_blank">
                                    Australian Catholic University
                                </a>
                            </p>
                            <p>
                                <a href="https://www.bali.polri.go.id" target="_blank">
                                    Bali Provincial and District Police Departments
                                </a>
                            </p>
                            <p>
                                <a href="https://www.gatesfoundation.org" target="_blank">
                                    Bill and Melinda Gates Foundation
                                </a>
                            </p>
                            <p>
                                <a href="https://www.international.gc.ca/world-monde/funding-financement/cfli-fcil/index.aspx?lang=eng" target="_blank">
                                    Canada Fund Local Initiatives
                                </a>
                            </p>
                            <p>
                                <a href="https://www.international.gc.ca/country-pays/indonesia-indonesie/jakarta.aspx?lang=eng" target="_blank">
                                    Canadian Embassy
                                </a>
                            </p>
                            <p>
                                <a href="https://www.climateandlandusealliance.org/" target="_blank">
                                    Climate and Land Use Alliance
                                </a>
                            </p>
                            <p>
                                <a href="https://www.csiro.au/en/" target="_blank">
                                    Commonwealth Scientific and Industrial Research Organisation
                                </a>
                            </p>
                            <p>
                                <a href="https://www.dlight.com/" target="_blank">
                                    D.light
                                </a>
                            </p>
                            <p>
                                <a href="https://www.daiken-ad.com">
                                    Daiken Corp
                                </a>
                            </p>
                            <p>
                                <a href="http://www.daiwa-grp.jp/english/" target="_blank">
                                    Daiwa Securities Group Inc.
                                </a>
                            </p>
                            <p>
                                <a href="https://danone.co.id/" target="_blank">
                                    Danone Indonesia
                                </a>
                            </p>
                            <p>
                                <a href="https://www.diageo.com/" target="_blank">
                                    DIAGEO
                                </a>
                            </p>
                            <p>
                                <a href="https://www.dondersfoundation.org/" target="_blank">
                                    Donders Foundation
                                </a>
                            </p>
                            <p>
                                <a href="https://www.eastbalipovertyproject.org/" target="_blank">
                                    East Bali Poverty Project
                                </a>
                            </p>
                            <p>
                                <a href="https://econusa.id/en/" target="_blank">
                                    Econusa
                                </a>
                            </p>
                            <p>
                                <a href="http://ecosoftt.org/" target="_blank">
                                    Ecosoft Pte Ltd
                                </a>
                            </p>
                            <p>
                                <a href="https://eigeradventure.com/" target="_blank">
                                    Eiger
                                </a>
                            </p>
                            <p>
                                <a href="https://corporate.exxonmobil.com/" target="_blank">
                                    ExxonMobil Foundation
                                </a>
                            </p>
                            <p>
                                <a href="http://www.fcubed.com.au/aspx/home.aspx" target="_blank">
                                    F-Cubed
                                </a>
                            </p>
                            <p>
                                <a href="https://www.instagram.com/soepeduli/?hl=en" target="_blank">
                                    Forum Soe Peduli
                                </a>
                            </p>
                            <p>
                                <a href="https://www.ugm.ac.id/" target="_blank">
                                    Gadjah Mada University
                                </a>
                            </p>
                            <p>
                                <a href="https://gggi.org/" target="_blank">
                                    Global Green Growth Institute
                                </a>
                            </p>
                            <p>
                                <a href="https://www.gojek.com/gopay/" target="_blank">
                                    GOJEK
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12 text-center px-lg-0 md-mb-5 wow fadeInUp">
                    <div class="price-item price-transform">
                        <div class="price-description">
                            <p>
                                <a href="https://www.haka.or.id/" target="_blank">
                                    HAkA
                                </a>
                            </p>
                            <p>
                                <a href="javascript:void(0)">
                                    Harada Sangyo
                                </a>
                            </p>
                            <p>
                                <a href="https://www.howittco.com" target="_blank">
                                    Howitt and Co
                                </a>
                            </p>
                            <p>
                                <a href="http://IDEO.org" target="_blank">
                                    IDEO.org
                                </a>
                            </p>
                            <p>
                                <a href="https://idibali.org" target="_blank">
                                    Indonesian Doctors Association Bali
                                </a>
                            </p>
                            <p>
                                <a href="https://www.pdui-pusat.org/pages/profil-pdui" target="_blank">
                                    Indonesian General Practitioners Association Bali
                                </a>
                            </p>
                            <p>
                                <a href="http://inibudi.org" target="_blank">
                                    Inibudi.org
                                </a>
                            </p>
                            <p>
                                <a href="http://www.jica.go.jp/" target="_blank">
                                    Japan International Cooperation Agency
                                </a>
                            </p>
                            <p>
                                <a href="javascript:void(0)">
                                    M/Y Dragonfly
                                </a>
                            </p>
                            <p>
                                <a href="https://kedaireka.id" target="_blank">
                                    Ministry of Education, Culture, Research and Development for the Kedaireka Program

                                </a>
                            </p>
                            <p>
                                <a href="https://www.mofa.go.jp/" target="_blank">
                                    Ministry of Foreign Affairs of Japan
                                </a>
                            </p>
                            <p>
                                <a href="https://kemenparekraf.go.id" target="_blank">
                                    Ministry of Tourism and Creative Economy of Indonesia
                                </a>
                            </p>
                            <p>
                                <a href="https://www.naviculamusic.com/" target="_blank">
                                    Navicula
                                </a>
                            </p>
                            <p>
                                <a href="https://www.instagram.com/nttcerdas/" target="_blank">
                                    NTT Cerdas
                                </a>
                            </p>
                            <p>
                                <a href="https://www.outlineindia.com/" target="_blank">
                                    Outline India
                                </a>
                            </p>
                            <p>
                                <a href="https://www.oxfam.org/en" target="_blank">
                                    Oxfam
                                </a>
                            </p>
                            <p>
                                <a href="javascript:void(0)">
                                    P4S (Pusat Pelatihan Pertanian dan Pedesaan Swadaya) Sedana Sari
                                </a>
                            </p>
                            <p>
                                <a href="javascript:void(0)">
                                    Pasar Rakyat
                                </a>
                            </p>
                            <p>
                                <a href="https://www.unpatti.ac.id/" target="_blank">
                                    Pattimura University
                                </a>
                            </p>
                            <p>
                                <a href="https://plan-international.or.id/id/" target="_blank">
                                    Plan International
                                </a>
                            </p>
                            <p>
                                <a href="https://www.gov.pl/" target="_blank">
                                    Polish Embassy
                                </a>
                            </p>
                            <p>
                                <a href="https://www.alamsiaklestari.com/" target="_blank">
                                    PT Alam Siak Lestari
                                </a>
                            </p>
                            <p>
                                <a href="https://www.purpose.com/about/purpose-labs/purpose-climate-lab/" target="_blank">
                                    Purpose Climate Lab
                                </a>
                            </p>
                            <p>
                                <a href="https://bpbd.baliprov.go.id/" target="_blank">
                                    Regional Board for Disaster Management (BPBD Bali) 
                                </a>
                            </p>
                            <p>
                                <a href="https://www.ricoh.com" target="_blank">
                                    RICOH
                                </a>
                            </p>
                            <p>
                                <a href="https://simavi.nl/en" target="_blank">
                                    Simavi
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12 col-sm-12 text-center pl-lg-0 wow fadeInRight">
                    <div class="price-item price-transform">
                        <div class="price-description" style="padding-bottom: 56px;">
                            <p>
                                <a href="https://www.stewartinvestors.com" target="_blank">
                                    Stewart Investors
                                </a>
                            </p>
                            <p>
                                <a href="https://unsyiah.ac.id" target="_blank">
                                    Syiah Kuala University
                                </a>
                            </p>
                            <p>
                                <a href="https://www.thebodyshop.co.id" target="_blank">
                                    The Body Shop Indonesia
                                </a>
                            </p>
                            <p>
                                <a href="https://kodam-udayana.mil.id" target="_blank">
                                    The Indonesian National Armed Forces Bali
                                </a>
                            </p>
                            <p>
                                <a href="https://www.isdb.org/" target="_blank">
                                    The Islamic Development Bank
                                </a>
                            </p>
                            <p>
                                <a href="https://www.packard.org/" target="_blank">
                                    The Packard Foundation
                                </a>
                            </p>
                            <p>
                                <a href="https://www.worldbank.org/en/home" target="_blank">
                                    The World Bank
                                </a>
                            </p>
                            <p>
                                <a href="https://www.instagram.com/timor.moringa/?hl=en" target="_blank">
                                    Timor Moringa
                                </a>
                            </p>
                            <p>
                                <a href="https://torajamelo.com" target="_blank">
                                    TORAJAMELO
                                </a>
                            </p>
                            <p>
                                <a href="https://global.toyota/jp/sustainability/esg/challenge2050/challenge6/ecogrant/" target="_blank">
                                    Toyota
                                </a>
                            </p>
                            <p>
                                <a href="https://www.instagram.com/umlok.coffee/?hl=en" target="_blank">
                                    Umah Lokal Coffee & Roastery
                                </a>
                            </p>
                            <p>
                                <a href="https://www.unimelb.edu.au/" target="_blank">
                                    UN Volunteer
                                </a>
                            </p>
                            <p>
                                <a href="https://www.undp.org/" target="_blank">
                                    UNDP
                                </a>
                            </p>
                            <p>
                                <a href="https://www.unimelb.edu.au/" target="_blank">
                                    University of Melbourne
                                </a>
                            </p>
                            <p>
                                <a href="https://www.visa.co.id/" target="_blank">
                                    VISA Indonesia
                                </a>
                            </p>
                            <p>
                                <a href="https://visinema.co/" target="_blank">
                                    Visinema Pictures
                                </a>
                            </p>
                            <p>
                                <a href="https://www.warmadewa.ac.id" target="_blank">
                                    Warmadewa University
                                </a>
                            </p>
                            <p>
                                <a href="https://watchdoc.co.id/" target="_blank">
                                    Watchdoc
                                </a>
                            </p>
                            <p>
                                <a href="http://wecare.id/http://wecare.id/" target="_blank">
                                    WeCare.id
                                </a>
                            </p>
                            <p>
                                <a href="https://www.wra.org.au" target="_blank">
                                    World Relief Australia
                                </a>
                            </p>
                            <p>
                                <a href="javascript:void(0)">
                                    Wujudkan! Indonesia
                                </a>
                            </p>
                            <p>
                                <a href="https://www.instagram.com/yayasanmaritim/" target="_blank">
                                    Yayasan Maritim Nusantara Lestari
                                </a>
                            </p>
                            <p>
                                <a href="https://www.instagram.com/papuamandirisentosa/?hl=en" target="_blank">
                                    Yayasan Papua Mandiri Sentosa
                                </a>
                            </p>
                            <p>
                                <a href="https://rumbia.co.id/" target="_blank">
                                    Yayasan RUMBIA Aceh
                                </a>
                            </p>
                            <p>
                                <a href="javascript:void(0)">
                                    Yayasan Wahana Pembangunan Berkelanjutan Indonesia (Yapemberi)
                                </a>
                            </p>
                            <p>
                                <a href="https://www.uny.ac.id" target="_blank">
                                    Yogyakarta National University
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Partners and Supporters ends -->
</template>

<script>
export default {
    name: 'Partners-Section'
}
</script>