<template>
    <!-- Communications And Community Outreach start -->
    <section class="comms">
        <div id="communications-and-community" class="count-comms-sec">
            <div class="count-comms-overlay"></div>
            <div class="container">
                <div class="row text-center count-comms-content">
                    <div class="col-12 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('comms_and_digital_section.comms_and_digital') }}
                        </h2>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-4rem">
            <div class="row">
                <div class="col-lg-3">
                    <div class="card bg-dark text-white communications">
                        <div class="item">
                            <div class="team-data-img wow fadeIn" data-wow-delay="350ms">
                                <div class="image" @mouseover="digitalInitiatives = true">
                                    <a href="#digitalInitiatives" class="scroll">
                                        <img src="@/assets/img/compressed/communications_digital_initiatives/digital-initiatives-600x400.png" :alt="$t('comms_and_digital_section.digital_initiatives')" class="img-responsive" data-no-retina>
                                        <h5 class="centered-thumb color-white font-weight-600 mb-2 text-capitalize font-primary">
                                            {{ $t('comms_and_digital_section.digital_initiatives') }}
                                        </h5>
                                        <div class="team-overlay center-block">
                                            <ul class="d-flex justify-content-center noborder">
                                                <li>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <span class="ml-md-2 kopernik">
                                                        <i class="fa fa-arrow-down fa-2x"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card bg-dark text-white communications">
                        <div class="item">
                            <div class="team-data-img wow fadeIn" data-wow-delay="350ms">
                                <div class="image" @mouseover="mediaCoverage = true">
                                    <a href="#mediaCoverage" class="scroll">
                                        <img src="@/assets/img/compressed/communications_digital_initiatives/media-coverage-600x400.png" :alt="$t('comms_and_digital_section.media_coverage')" class="img-responsive" data-no-retina>
                                        <h5 class="centered-thumb color-white font-weight-600 mb-2 text-capitalize font-primary">
                                            {{ $t('comms_and_digital_section.media_coverage') }}
                                        </h5>
                                        <div class="team-overlay center-block">
                                            <ul class="d-flex justify-content-center noborder">
                                                <li>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <span class="ml-md-2 kopernik">
                                                        <i class="fa fa-arrow-down fa-2x"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card bg-dark text-white communications">
                        <div class="item">
                            <div class="team-data-img wow fadeIn" data-wow-delay="350ms">
                                <div class="image" @mouseover="events = true">
                                    <a href="#events" class="scroll">
                                        <img src="@/assets/img/compressed/communications_digital_initiatives/event-600x400.png" :alt="$t('comms_and_digital_section.events')" class="img-responsive" data-no-retina>
                                        <h5 class="centered-thumb color-white font-weight-600 mb-2 text-capitalize font-primary">
                                            {{ $t('comms_and_digital_section.events') }}
                                        </h5>
                                        <div class="team-overlay center-block">
                                            <ul class="d-flex justify-content-center noborder">
                                                <li>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <span class="ml-md-2 kopernik">
                                                        <i class="fa fa-arrow-down fa-2x"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card bg-dark text-white communications">
                        <div class="item">
                            <div class="team-data-img wow fadeIn" data-wow-delay="350ms">
                                <div class="image" @mouseover="digitalOutreach = true">
                                    <a href="#digitalOutreach" class="scroll">
                                        <img src="@/assets/img/compressed/communications_digital_initiatives/digital-outreach-600x400.png" :alt="$t('comms_and_digital_section.digital_channel')" class="img-responsive" data-no-retina>
                                        <h5 class="centered-thumb color-white font-weight-600 font-18 mb-2 text-capitalize font-primary py-sm-4 m-md-2">
                                            {{ $t('comms_and_digital_section.digital_channel') }}
                                        </h5>
                                        <div class="team-overlay center-block">
                                            <ul class="d-flex justify-content-center noborder">
                                                <li>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <span class="ml-md-2 kopernik">
                                                        <i class="fa fa-arrow-down fa-2x"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Communications And Community Outreach ends -->
    
    <!-- Digital Initiatives start -->
    <section class="comms" v-show="digitalInitiatives">
        <div id="digitalInitiatives" class="count-digital-sec">
            <div class="count-digital-overlay"></div>
            <div class="container">
                <div class="row text-center count-digital-content">
                    <div class="col-12 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('comms_and_digital_section.digital_initiatives') }}
                        </h2>
                        <p class="text-des">
                            {{ $t('comms_and_digital_section.digital_initiatives_subs') }}
                        </p>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-4rem">
            <div class="row">
                <div class="col-lg-4">
                    <div class="card bg-dark text-white communications">
                        <div class="item">
                            <div class="team-data-img wow fadeIn" data-wow-delay="350ms">
                                <div class="image">
                                    <router-link :to="{name: 'Digital-project-1'}" class="scroll text-white">
                                        <img src="@/assets/img/compressed/communications_digital_initiatives/digital_media_initiatives/SoR-650x340.jpg" :alt="$t('comms_and_digital_section.digital_initiatives_post_1')">
                                        <h5 class="centered-thumb color-white font-weight-700 mb-2 text-capitalize font-secondary">
                                            {{ $t('comms_and_digital_section.digital_initiatives_post_1') }}
                                        </h5>
                                    </router-link>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card bg-dark text-white communications">
                        <div class="item">
                            <div class="team-data-img wow fadeIn" data-wow-delay="350ms">
                                <div class="image">
                                    <router-link :to="{name: 'Digital-project-2'}" class="scroll text-white">
                                        <img src="@/assets/img/compressed/communications_digital_initiatives/digital_media_initiatives/Solutions-Catalog-650x340.jpg" :alt="$t('comms_and_digital_section.digital_initiatives_post_2')">
                                        <h5 class="centered-thumb color-white font-weight-700 mb-2 text-capitalize font-secondary">
                                            {{ $t('comms_and_digital_section.digital_initiatives_post_2') }}
                                        </h5>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="card bg-dark text-white communications">
                        <div class="item">
                            <div class="team-data-img wow fadeIn" data-wow-delay="350ms">
                                <div class="image">
                                    <router-link :to="{name: 'Digital-project-3'}" class="scroll text-white">
                                        <img src="@/assets/img/compressed/communications_digital_initiatives/digital_media_initiatives/VR-650x340.jpg" :alt="$t('comms_and_digital_section.digital_initiatives_post_3')">
                                        <h5 class="centered-thumb color-white font-weight-700 mb-2 text-capitalize font-secondary">
                                            {{ $t('comms_and_digital_section.digital_initiatives_post_3') }}
                                        </h5>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Digital Initiatives ends -->

    <!-- Media Coverage start -->
    <section class="comms" v-show="mediaCoverage">
        <div id="mediaCoverage" class="count-media-sec">
            <div class="count-media-overlay"></div>
            <div class="container">
                <div class="row text-center count-media-content">
                    <div class="col-12 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('comms_and_digital_section.media_coverage') }}
                        </h2>
                        <p class="text-des">
                            {{ $t('comms_and_digital_section.media_coverage_subs') }}
                        </p>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-sm-4">
            <div class="row">
                <div class="col-sm-12">
                    <div class="main-content-media">
                        <div class="owl-carousel owl-theme">
                            <div class="item">
                                <a target="_blank" href="https://theconversation.com/pulau-plastik-campaign-has-raised-public-awareness-on-plastic-waste-yet-challenges-remain-167815">
                                    <img src="@/assets/img/compressed/communications_digital_initiatives/media_coverage/the-conversation-967x683.jpg" :alt="$t('comms_and_digital_section.media_coverage_post1')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://theconversation.com/pulau-plastik-campaign-has-raised-public-awareness-on-plastic-waste-yet-challenges-remain-167815">
                                        {{ $t('comms_and_digital_section.media_coverage_post1') }}
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <a target="_blank" href="https://www.nowbali.co.id/innovation-to-the-rescue-fighting-covid-with-technology/">
                                    <img src="@/assets/img/compressed/communications_digital_initiatives/media_coverage/now-bali-967x683.jpg" :alt="$t('comms_and_digital_section.media_coverage_post2')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://www.nowbali.co.id/innovation-to-the-rescue-fighting-covid-with-technology/">
                                        {{ $t('comms_and_digital_section.media_coverage_post2') }}
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <a target="_blank" href="https://nextbillion.net/sustainable-development-lean-experimentation-sdgs/">
                                    <img src="@/assets/img/compressed/communications_digital_initiatives/media_coverage/next-billion-967x683.jpg" :alt="$t('comms_and_digital_section.media_coverage_post3')">
                                </a>
                            <div class="desc font-secondary">
                                <a target="_blank" href="https://nextbillion.net/sustainable-development-lean-experimentation-sdgs/">
                                    {{ $t('comms_and_digital_section.media_coverage_post3') }}
                                </a>
                            </div>
                            </div>
                            <div class="item">
                                <a target="_blank" href="https://www.thejakartapost.com/academia/2021/03/05/digital-donations---new-potential-to-accelerate-philanthropy-.html">
                                    <img src="@/assets/img/compressed/communications_digital_initiatives/media_coverage/jp-digital-967x683.jpg" :alt="$t('comms_and_digital_section.media_coverage_post4')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://www.thejakartapost.com/academia/2021/03/05/digital-donations---new-potential-to-accelerate-philanthropy-.html">
                                        {{ $t('comms_and_digital_section.media_coverage_post4') }}
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <a target="_blank" href="https://www.kompas.com/hype/read/2021/04/28/205703966/film-pulau-plastik-diharapkan-jadi-gerbang-film-dokumenter-tayang-di">
                                    <img src="@/assets/img/compressed/communications_digital_initiatives/media_coverage/kompas-967x683.jpg" :alt="$t('comms_and_digital_section.media_coverage_post5')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://www.kompas.com/hype/read/2021/04/28/205703966/film-pulau-plastik-diharapkan-jadi-gerbang-film-dokumenter-tayang-di">
                                        {{ $t('comms_and_digital_section.media_coverage_post5') }}
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <a target="_blank" href="https://medium.com/pulse-lab-jakarta/designing-a-community-based-air-quality-monitoring-system-to-mitigate-air-pollution-health-risks-3e527e607427">
                                    <img src="@/assets/img/compressed/communications_digital_initiatives/media_coverage/pulselab-967x683.jpg" :alt="$t('comms_and_digital_section.media_coverage_post5')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://medium.com/pulse-lab-jakarta/designing-a-community-based-air-quality-monitoring-system-to-mitigate-air-pollution-health-risks-3e527e607427">
                                        {{ $t('comms_and_digital_section.media_coverage_post6') }}
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <a target="_blank" href="https://www.thejakartapost.com/opinion/2021/12/11/from-single-use-pads-to-sustainable-underwear-.html">
                                    <img src="@/assets/img/compressed/communications_digital_initiatives/media_coverage/jp-single-use-967x683.jpg" :alt="$t('comms_and_digital_section.media_coverage_post5')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://www.thejakartapost.com/opinion/2021/12/11/from-single-use-pads-to-sustainable-underwear-.html">
                                        {{ $t('comms_and_digital_section.media_coverage_post7') }}
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <a target="_blank" href="https://www.european-rubber-journal.com/article/2071224/kopernik-helping-rubber-farmers-to-cap-lost-yields">
                                    <img src="@/assets/img/compressed/communications_digital_initiatives/media_coverage/european-967x683.jpg" :alt="$t('comms_and_digital_section.media_coverage_post5')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://www.european-rubber-journal.com/article/2071224/kopernik-helping-rubber-farmers-to-cap-lost-yields">
                                        {{ $t('comms_and_digital_section.media_coverage_post8') }}
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <a target="_blank" href="https://www.youtube.com/watch?v=glqADxgr5xA">
                                    <img src="@/assets/img/compressed/communications_digital_initiatives/media_coverage/sea-967x683.jpg" :alt="$t('comms_and_digital_section.media_coverage_post5')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://www.youtube.com/watch?v=glqADxgr5xA">
                                        {{ $t('comms_and_digital_section.media_coverage_post9') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="owl-theme">
                            <div class="owl-controls">
                                <div class="custom-nav owl-nav"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Media Coverage ends -->

    <!-- Events start -->
    <section class="comms" v-show="events">
        <div id="events" class="count-events-sec">
            <div class="count-events-overlay"></div>
            <div class="container">
                <div class="row text-center count-events-content">
                    <div class="col-12 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('comms_and_digital_section.events') }}
                        </h2>
                        <p class="text-des">
                            {{ $t('comms_and_digital_section.events_subs') }}
                        </p>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid pt-sm-4">
            <div class="row">
                <div class="col-sm-12">
                    <div class="main-content-events">
                        <div class="owl-carousel owl-theme">
                            <div class="item">
                                <a target="_blank" href="https://www.youtube.com/watch?v=lmr6Cqj3hAs">
                                    <img src="@/assets/img/compressed/communications_digital_initiatives/events/unv-967x683.jpg" :alt="$t('comms_and_digital_section.events_post_1')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://www.youtube.com/watch?v=lmr6Cqj3hAs">
                                    {{ $t('comms_and_digital_section.events_post_1') }}
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <a arget="_blank" href="https://kopernik.info/jp/%E6%9C%80%E6%96%B0%E3%83%8B%E3%83%A5%E3%83%BC%E3%82%B9%E3%83%BB%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88/%E3%83%8B%E3%83%A5%E3%83%BC%E3%82%B9/%E3%82%B3%E3%83%9A%E3%83%AB%E3%83%8B%E3%82%AF%E3%83%BB%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A9%E3%83%A02021%E3%82%92%E9%96%8B%E5%82%AC%E3%81%97%E3%81%BE%E3%81%97%E3%81%9F">
                                    <img src="@/assets/img/compressed/communications_digital_initiatives/events/kopernik-forum-967x683.jpg" :alt="$t('comms_and_digital_section.events_post_2')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://kopernik.info/jp/%E6%9C%80%E6%96%B0%E3%83%8B%E3%83%A5%E3%83%BC%E3%82%B9%E3%83%BB%E3%82%A4%E3%83%99%E3%83%B3%E3%83%88/%E3%83%8B%E3%83%A5%E3%83%BC%E3%82%B9/%E3%82%B3%E3%83%9A%E3%83%AB%E3%83%8B%E3%82%AF%E3%83%BB%E3%83%95%E3%82%A9%E3%83%BC%E3%83%A9%E3%83%A02021%E3%82%92%E9%96%8B%E5%82%AC%E3%81%97%E3%81%BE%E3%81%97%E3%81%9F">
                                    {{ $t('comms_and_digital_section.events_post_2') }}
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <a target="_blank" href="https://kopernik.info/en/news-events/news/kopernik-partnered-with-ideoorg-on-research-to-understand-the-situation-of-womens-access-to-financial-services-in-indonesia">
                                    <img src="@/assets/img/compressed/communications_digital_initiatives/events/ideo-967x683.jpg" :alt="$t('comms_and_digital_section.events_post_3')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://kopernik.info/en/news-events/news/kopernik-partnered-with-ideoorg-on-research-to-understand-the-situation-of-womens-access-to-financial-services-in-indonesia">
                                    {{ $t('comms_and_digital_section.events_post_3') }}
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <a target="_blank" href="https://press.powercircle.org/posts/blogposts/connecting-the-world-to-clean-power">
                                    <img src="@/assets/img/compressed/communications_digital_initiatives/events/power-summit-967x683.jpg" :alt="$t('comms_and_digital_section.events_post_4')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://press.powercircle.org/posts/blogposts/connecting-the-world-to-clean-power">
                                    {{ $t('comms_and_digital_section.events_post_4') }}
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <a target="_blank" href="https://www.gojek.com/sustainability/">
                                    <img src="@/assets/img/compressed/communications_digital_initiatives/events/gojek-967x683.jpg" :alt="$t('comms_and_digital_section.events_post_5')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://www.gojek.com/sustainability/">
                                    {{ $t('comms_and_digital_section.events_post_5') }}
                                    </a>
                                </div>
                            </div>
                            <div class="item">
                                <a target="_blank" href="https://www.youtube.com/watch?v=GuSajuAcBn8">
                                    <img src="@/assets/img/compressed/communications_digital_initiatives/events/water2me-967x683.jpg" :alt="$t('comms_and_digital_section.events_post_6')">
                                </a>
                                <div class="desc font-secondary">
                                    <a target="_blank" href="https://www.youtube.com/watch?v=GuSajuAcBn8">
                                    {{ $t('comms_and_digital_section.events_post_6') }}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="owl-theme">
                            <div class="owl-controls">
                                <div class="custom-nav owl-nav"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Events ends -->

    <!-- Digital Channels And Outreach start -->
    <section class="comms" v-show="digitalOutreach">
        <div id="digitalOutreach" class="count-outreach-sec" @mouseover="fetchPluginsCount">
            <div class="count-outreach-overlay"></div>
            <div class="container">
                <div class="row text-center count-outreach-content">
                    <div class="col-12 pb-md-5 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('comms_and_digital_section.digital_channel') }}
                        </h2>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container pt-sm-5">
            <div class="row text-center count-content">
                <div class="col-12 col-lg-4 count-li wow fadeInUp">
                    <h2 class="num font-kopernik font-primary">
                        <vue3-autocounter ref="counter1" :startAmount="0" :endAmount="value1" :duration="3" prefix="" suffix="+" separator="," decimalSeparator="" :decimals="0" :autoinit="true" />
                    </h2>
                    <h4 class="heading font-secondary">
                        {{ $t('comms_and_digital_section.digitaL_outreach_value_1') }}
                    </h4>
                    <span class="fly-line"></span>
                </div>
                <div class="col-12 col-lg-4 count-li wow fadeInUp">
                    <h2 class="num font-kopernik font-primary">
                        <vue3-autocounter ref="counter2" :startAmount="0" :endAmount="value2" :duration="3" prefix="" suffix="+" separator="," decimalSeparator="" :decimals="0" :autoinit="true" />
                    </h2>
                    <h4 class="heading font-secondary">
                        {{ $t('comms_and_digital_section.digitaL_outreach_value_2') }}
                    </h4>
                    <span class="fly-line"></span>
                </div>
                <div class="col-12 col-lg-4 count-li wow fadeInUp">
                    <h2 class="num font-kopernik font-primary">
                        <vue3-autocounter ref="counter3" :startAmount="0" :endAmount="value3" :duration="3" prefix="" suffix="+" separator="," decimalSeparator="" :decimals="0" :autoinit="true" />
                    </h2>
                    <h4 class="heading font-secondary">
                        {{ $t('comms_and_digital_section.digitaL_outreach_value_3') }}
                    </h4>
                    <span class="fly-line"></span>
                </div>
            </div>
        </div>
    </section>
    <!-- Digital Channels And Outreach ends -->
</template>

<script>
export default {
    name: "Comms-Section",
    data() {
        return {
            digitalInitiatives: false,
            events: false,
            digitalOutreach: false,
            mediaCoverage: false,
            /* digital channels outreach */
            value1: 31000,
            value2: 9000,
            value3: 150,
            duration: 15000,
        }
    },
    methods: {
        fetchPluginsCount() {
            this.$refs.counter1.start()
            this.$refs.counter2.start()
            this.$refs.counter3.start()
        }
    }
}
</script>