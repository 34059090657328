<template>
    <!-- Detail Project starts -->
    <section id="blog" class="details-project bg-light-gray text-left" @keydown.esc="backMethods">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <!-- Project List Inner -->
                    <div class="blog-listing-inner heading-space-half">
                        <div class="image">
                            <img src="@/assets/img/compressed/solution_in_action/Youth-for-Papua-1110x450.jpg" :alt="$t('category_solution.project_5_title')">
                        </div>
                    </div>
                </div>
                <!-- Project Left Listing -->
                <div class="col-lg-8 col-md-7">
                    <div class="blog-box heading-space-half m-md-0">
                        <!-- Project List Inner -->
                        <div class="blog-listing-inner news_item wow fadeIn" data-wow-delay="300ms">
                            <div class="news_desc">
                                <h3 class="pb-4 font-weight-normal font-primary text-uppercase" v-html="$t('category_solution.project_5_title_content')">
                                </h3>
                                <div class="content-editor" v-html="$t('category_solution.project_5_detail_content_1')"></div>
                                <p class="pb-4">
                                    <ul>
                                        <li class="ml-md-0">
                                            <div class="left pr-2">
                                                <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                            </div>
                                            <p class="pr-3" v-html="$t('category_solution.project_5_list_item_1_title')">
                                            </p>
                                        </li>
                                        <li class="ml-md-0">
                                            <div class="left pr-2">
                                                <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                            </div>
                                            <p class="pr-3" v-html="$t('category_solution.project_5_list_item_2_title')">
                                            </p>
                                        </li>
                                        <li class="ml-md-0">
                                            <div class="left pr-2">
                                                <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                            </div>
                                            <p class="pr-3" v-html="$t('category_solution.project_5_list_item_3_title')">
                                            </p>
                                        </li>
                                        <li class="ml-md-0">
                                            <div class="left pr-2">
                                                <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                            </div>
                                            <p class="pr-3" v-html="$t('category_solution.project_5_list_item_4_title')">
                                            </p>
                                        </li>
                                        <li class="ml-md-0">
                                            <div class="left pr-2">
                                                <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                            </div>
                                            <p class="pr-3" v-html="$t('category_solution.project_5_list_item_5_title')">
                                            </p>
                                        </li>
                                    </ul>
                                </p>
                                <div class="content-editor" v-html="$t('category_solution.project_5_detail_content_2')"></div>
                            </div>
                            <!-- Button Close -->
                            <div class="wow fadeIn" data-wow-delay="600ms">
                                <span class="close-btn-bottom1 kopernik">
                                    <a href="project-4" class="color-grey">
                                        <i class="fa fa-arrow-left fa-2x"></i>
                                    </a>
                                </span>
                                <span class="close-btn-bottom2 kopernik">
                                    <a href="project-6" class="color-grey">
                                        <i class="fa fa-arrow-right fa-2x"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Project Widgets -->
                <div class="col-lg-4 col-md-5">
                    <div class="text-left">
                        <!-- Categories -->
                        <div class="widget heading-space-half wow fadeIn" data-wow-delay="400ms">
                            <h5 class="color-black font-weight-600 text-uppercase font-primary">
                                {{ $t('category_solution.category') }}
                            </h5>
                            <div>
                                <div class="categories-name">
                                    <p class="font-16 color-grey">
                                        <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik"> {{ $t('category_solution.category_development') }}
                                    </p>
                                </div>
                                <div class="categories-name">
                                    <p class="font-16 color-grey">
                                        <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik"> {{ $t('category_lean.category_partnership') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Detail Project ends -->
</template>

<script>
export default {
    name: 'Project 5',
    methods: {
        backMethods() {
            this.$router.go(-1)
        }
    }
}
</script>