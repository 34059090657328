<template>
    <!-- Detail Project starts -->
    <section id="blog" class="details-project bg-light-gray text-left" @keydown.esc="backMethods">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <!-- Project List Inner -->
                    <div class="blog-listing-inner heading-space-half">
                        <div class="image">
                            <img src="@/assets/img/compressed/solution_in_action/COVID-19-Response-1110x450.jpg" :alt="$t('category_solution.project_1_1_title') +' '+ $t('category_solution.project_1_2_title')">
                        </div>
                    </div>
                </div>
                <!-- Project Left Listing -->
                <div class="col-lg-8 col-md-7">
                    <div class="blog-box heading-space-half m-md-0">
                        <!-- Project List Inner -->
                        <div class="blog-listing-inner news_item wow fadeIn" data-wow-delay="300ms">
                            <div class="news_desc">
                                <h3 class="pb-4 font-weight-normal font-primary text-uppercase" v-html="$t('category_solution.project_1_title_content')">
                                </h3>
                                <div class="content-editor" v-html="$t('category_solution.project_1_detail_content_1')"></div>
                                <p class="pb-4">
                                    <ul>
                                        <li class="ml-md-4 font-weight-bold" v-html="$t('category_solution.project_1_list_item_1_title')">
                                        </li>
                                        <br>
                                        <li class="ml-md-5">
                                            <div class="left">
                                                <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                            </div>
                                            <p class="pl-2rem pr-3 font-weight-bold" v-html="$t('category_solution.project_1_list_item_1_detail_1_title')">
                                            </p>
                                        </li>
                                        <li class="ml-md-5" v-html="$t('category_solution.project_1_list_item_1_detail_1_detail')">
                                        </li>
                                        <br>
                                        <li class="ml-md-5">
                                            <div class="left">
                                                <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                            </div>
                                            <p class="pl-2rem pr-3 font-weight-bold" v-html="$t('category_solution.project_1_list_item_1_detail_2_title')">
                                            </p>
                                        </li>
                                        <li class="ml-md-5" v-html="$t('category_solution.project_1_list_item_1_detail_2_detail')">
                                        </li>
                                        <br>
                                        <li class="ml-md-4 font-weight-bold" v-html="$t('category_solution.project_1_list_item_2_title')">
                                        </li>
                                        <br>
                                        <li class="ml-md-5">
                                            <div class="left">
                                                <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                            </div>
                                            <p class="pl-2rem pr-3 font-weight-bold" v-html="$t('category_solution.project_1_list_item_2_detail_1_title')">
                                            </p>
                                        </li>
                                        <li class="ml-md-5" v-html="$t('category_solution.project_1_list_item_2_detail_1_detail')">
                                        </li>
                                        <br>
                                        <li class="ml-md-5">
                                            <div class="left">
                                                <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                            </div>
                                            <p class="pl-2rem pr-3 font-weight-bold" v-html="$t('category_solution.project_1_list_item_2_detail_2_title')">
                                            </p>
                                        </li>
                                        <li class="ml-md-5" v-html="$t('category_solution.project_1_list_item_2_detail_2_detail')">
                                        </li>
                                    </ul>
                                </p>
                                <div class="content-editor" v-html="$t('category_solution.project_1_detail_content_2')"></div>
                            </div>
                            <!-- Button Close -->
                            <div class="wow fadeIn" data-wow-delay="600ms">
                                <span class="close-btn-bottom2 kopernik">
                                    <a href="project-2" class="color-grey">
                                        <i class="fa fa-arrow-right fa-2x"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Project Widgets -->
                <div class="col-lg-4 col-md-5">
                    <div class="text-left">
                        <!-- Categories -->
                        <div class="widget heading-space-half wow fadeIn" data-wow-delay="400ms">
                            <h5 class="color-black font-weight-600 text-uppercase font-primary">
                                {{ $t('category_solution.category') }}
                            </h5>
                            <div>
                                <div class="categories-name">
                                    <p class="font-16 color-grey">
                                        <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik"> {{ $t('category_solution.category_response') }}
                                    </p>
                                </div>
                                <div class="categories-name">
                                    <p class="font-16 color-grey">
                                        <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik"> {{ $t('category_solution.category_distribution') }}
                                    </p>
                                </div>
                                <div class="categories-name">
                                    <p class="font-16 color-grey">
                                        <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik"> {{ $t('category_solution.category_development') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <!-- Call Out Box -->
                        <div class="widget-parallax heading-space-half mb-0 parallax-setting parallaxie position-relative wow fadeIn" data-wow-delay="500ms">
                            <div class="bg-overlay bg-kopernik2 opacity-7"></div>
                            <div class="search-box position-relative bg-change">
                                <h5 class="color-white font-weight-600 text-capitalize font-primary mb-3">
                                    {{ $t('category_solution.project_1_call_out_1') }}
                                </h5>
                                <p class="font-secondary color-white text-white font-16 font-weight-normal mb-4">
                                    <ul>
                                    <li class="pr-3" v-html="$t('category_solution.project_1_call_out_2')">
                                    </li>
                                    <li class="pr-3" v-html="$t('category_solution.project_1_call_out_3')">
                                    </li>
                                    <li class="pr-3" v-html="$t('category_solution.project_1_call_out_4')">
                                    </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Detail Project ends -->
</template>

<script>
export default {
    name: 'Project 1',
    methods: {
        backMethods() {
            this.$router.go(-1)
        }
    }
}
</script>