<template>
    <!-- Footer starts -->
    <footer class="bg-kopernik footer-transform-padding">
        <div class="container">
            <div class="row">
                <div class="col-md-12 col-sm-12 text-center">
                    <ul class="footer-icons mb-4">
                        <li>
                            <a href="https://www.facebook.com/thekopernik" target="_blank" class="wow fadeInUp facebook">
                                <i class="fab fa-facebook-f"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/thekopernik" target="_blank" class="wow fadeInDown twitter">
                                <i class="fab fa-twitter"></i>
                                
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/kopernik.info/" target="_blank" class="wow fadeInUp instagram">
                                <i class="fab fa-instagram"></i>
                            </a>
                        </li>
                        <li>
                            <a class="wow fadeInDown linkedin-bg-hvr" target="_blank" href="https://www.linkedin.com/company/kopernik/">
                                <i class="fab fa-linkedin-in" aria-hidden="true"></i>
                            </a>
                        </li>
                    </ul>
                    <p class="copyrights mt-2 mb-2">©2022 <a href="https://kopernik.info" target="_blank">Kopernik</a></p>
                </div>
            </div>
        </div>
    </footer>
    <!-- Footer ends -->
</template>

<script>
export default {
    name: 'Footer'
}
</script>