<template>
    <!-- SDG's start -->
    <section class="sdg">
        <div id="sdgs" class="count-sec">
            <div class="count-overlay"></div>
            <div class="container">
                <div class="row text-center count-content">
                    <div class="col-12 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('sdgs_section.title_1') }}
                            <br>
                            {{ $t('sdgs_section.title_2') }}
                        </h2>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="row">
                <div class="container-fluid pt-sm-4">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="gallery sdgs wow fadeInLeft">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal1">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg1.svg" alt="SDG1">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg1_id.svg" alt="SDG1">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg1_jp.png" alt="SDG1">
                                </a>
                            </div>
                            <div class="gallery sdgs wow fadeInLeft">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal2">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg2.svg" alt="SDG2">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg2_id.svg" alt="SDG2">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg2_jp.png" alt="SDG2">
                                </a>
                            </div>
                            <div class="gallery sdgs wow fadeInLeft">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal3">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg3.svg" alt="SDG3">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg3_id.svg" alt="SDG3">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg3_jp.png" alt="SDG3">
                                </a>
                            </div>
                            <div class="gallery sdgs wow fadeInLeft">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal4">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg4.svg" alt="SDG4">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg4_id.svg" alt="SDG4">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg4_jp.png" alt="SDG4">
                                </a>
                            </div>
                            <div class="gallery sdgs wow fadeInLeft">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal5">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg5.svg" alt="SDG5">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg5_id.svg" alt="SDG5">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg5_jp.png" alt="SDG5">
                                </a>
                            </div>
                            <div class="gallery sdgs wow fadeInLeft">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal6">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg6.svg" alt="SDG1">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg6_id.svg" alt="SDG6">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg6_jp.png" alt="SDG6">
                                </a>
                            </div>
                            <div class="gallery sdgs wow fadeInRight">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal7">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg7.svg" alt="SDG7">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg7_id.svg" alt="SDG7">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg7_jp.png" alt="SDG7">
                                </a>
                            </div>
                            <div class="gallery sdgs wow fadeInRight">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal8">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg8.svg" alt="SDG8">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg8_id.svg" alt="SDG8">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg8_jp.png" alt="SDG8">
                                </a>
                            </div>
                            <div class="gallery sdgs wow fadeInRight">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal9">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg9.svg" alt="SDG9">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg9_id.svg" alt="SDG9">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg9_jp.png" alt="SDG9">
                                </a>
                            </div>
                            <div class="gallery sdgs wow fadeInRight">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal10">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg10.svg" alt="SDG10">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg10_id.svg" alt="SDG10">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg10_jp.png" alt="SDG10">
                                </a>
                            </div>
                            <div class="gallery sdgs wow fadeInRight">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal11">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg11.svg" alt="SDG11">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg11_id.svg" alt="SDG11">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg11_jp.png" alt="SDG11">
                                </a>
                            </div>
                            <div class="gallery sdgs wow fadeInRight">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal12">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg12.svg" alt="SDG12">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg12_id.svg" alt="SDG12">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg12_jp.png" alt="SDG12">
                                </a>
                            </div>
                            <div class="gallery sdgs wow fadeInLeft">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal13">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg13.svg" alt="SDG13">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg13_id.svg" alt="SDG13">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg13_jp.png" alt="SDG13">
                                </a>
                            </div>
                            <div class="gallery sdgs wow fadeInLeft">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal14">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg14.svg" alt="SDG14">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg14_id.svg" alt="SDG14">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg14_jp.png" alt="SDG14">
                                </a>
                            </div>
                            <div class="gallery sdgs wow fadeInLeft">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal15">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg15.svg" alt="SDG15">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg15_id.svg" alt="SDG15">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg15_jp.png" alt="SDG15">
                                </a>
                            </div>
                            <!--<div class="gallery sdgs wow fadeInLeft">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal16">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg16.svg" alt="SDG16">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg16_id.svg" alt="SDG16">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg16_jp.png" alt="SDG16">
                                </a>
                            </div>-->
                            <div class="gallery sdgs wow fadeInLeft">
                                <a href="javascript:void(0)" data-toggle="modal" data-target="#goal17">
                                    <img v-if="this.$i18n.locale == 'en'" src="@/assets/img/sdgs/sdg17.svg" alt="SDG17">
                                    <img v-else-if="this.$i18n.locale == 'id'" src="@/assets/img/sdgs/sdg17_id.svg" alt="SDG17">
                                    <img v-else-if="this.$i18n.locale == 'jp'" src="@/assets/img/sdgs/sdg17_jp.png" alt="SDG17">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal Goal 1-->
        <div class="modal fade" id="goal1" tabindex="-1" role="dialog" aria-labelledby="goal1ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-primary" id="goal1ModalLabel">
                            {{ $t('sdgs_section.sdg_1') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/donate/covid19-response-supporting-communities-affected-by-the-covid19-pandemic" target="_blank">
                                        {{ $t('sdgs_section.sdg_1_post_1') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a v-if="['en', 'jp'].includes(this.$i18n.locale)" href="https://static1.squarespace.com/static/5d94e54cb06c703e5199d288/t/60d24a684f78f942d0da8a9b/1624394378656/Indonesia_Women_Money_English_June2021.pdf" target="_blank">
                                        {{ $t('sdgs_section.sdg_1_post_2') }}
                                    </a>
                                    <a v-if="this.$i18n.locale == 'id'" href="https://static1.squarespace.com/static/5d94e54cb06c703e5199d288/t/60d24a684f78f942d0da8a9b/1624394378656/Indonesia_Women_Money_English_June2021.pdf" target="_blank">
                                        {{ $t('sdgs_section.sdg_1_post_2') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/current-projects/strengthening-womens-economic-empowerment-through-business-development" target="_blank">
                                        {{ $t('sdgs_section.sdg_1_post_3') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="javascript:void(0)">
                                        {{ $t('sdgs_section.sdg_1_post_4') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Goal 2-->
        <div class="modal fade" id="goal2" tabindex="-1" role="dialog" aria-labelledby="goal2ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-primary" id="goal2ModalLabel">
                            {{ $t('sdgs_section.sdg_2') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a v-if="['en', 'jp'].includes(this.$i18n.locale)" href="https://kopernik.info/en/current-projects/food-production-through-aquaponics-supporting-covid19-affected-communities-in-bali" target="_blank">
                                        {{ $t('sdgs_section.sdg_2_post_1') }}
                                    </a>
                                    <a v-if="this.$i18n.locale == 'id'" href="https://kopernik.info/en/current-projects/food-production-through-aquaponics-supporting-covid19-affected-communities-in-bali" target="_blank">
                                        {{ $t('sdgs_section.sdg_2_post_1') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/donate/covid19-response-supporting-communities-affected-by-the-covid19-pandemic" target="_blank">
                                        {{ $t('sdgs_section.sdg_2_post_2') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/current-projects/establishing-communityowned-food-garden-a-stunting-prevention-initiative" target="_blank">
                                        {{ $t('sdgs_section.sdg_2_post_3') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="javascript:void(0)">
                                        {{ $t('sdgs_section.sdg_2_post_4') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Goal 3-->
        <div class="modal fade" id="goal3" tabindex="-1" role="dialog" aria-labelledby="goal3ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-18 font-primary" id="goal3ModalLabel">
                            {{ $t('sdgs_section.sdg_3') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a v-if="['en', 'jp'].includes(this.$i18n.locale)" href="https://kopernik.info/en/current-projects/food-production-through-aquaponics-supporting-covid19-affected-communities-in-bali" target="_blank">
                                        {{ $t('sdgs_section.sdg_3_post_1') }}
                                    </a>
                                    <a v-if="this.$i18n.locale == 'id'" href="https://kopernik.info/en/current-projects/food-production-through-aquaponics-supporting-covid19-affected-communities-in-bali" target="_blank">
                                        {{ $t('sdgs_section.sdg_3_post_1') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a v-if="['en', 'jp'].includes(this.$i18n.locale)" href="https://kopernik.info/en/current-projects/experimenting-for-social-innovation-communitylevel-air-quality-monitoring" target="_blank">
                                        {{ $t('sdgs_section.sdg_3_post_2') }}
                                    </a>
                                    <a v-if="this.$i18n.locale == 'id'" href="https://kopernik.info/en/current-projects/experimenting-for-social-innovation-communitylevel-air-quality-monitoring" target="_blank">
                                        {{ $t('sdgs_section.sdg_3_post_2') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a v-if="['en', 'jp'].includes(this.$i18n.locale)" href="https://kopernik.info/en/donate/covid19-response-supporting-communities-affected-by-the-covid19-pandemic" target="_blank">
                                        {{ $t('sdgs_section.sdg_3_post_3') }}
                                    </a>
                                    <a v-if="this.$i18n.locale == 'id'" href="https://kopernik.info/en/donate/covid19-response-supporting-communities-affected-by-the-covid19-pandemic" target="_blank">
                                        {{ $t('sdgs_section.sdg_3_post_3') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a v-if="['en', 'jp'].includes(this.$i18n.locale)" href="https://kopernik.info/en/current-projects/establishing-communityowned-food-garden-a-stunting-prevention-initiative" target="_blank">
                                        {{ $t('sdgs_section.sdg_3_post_4') }}
                                    </a>
                                    <a v-if="this.$i18n.locale == 'id'" href="https://kopernik.info/en/current-projects/establishing-communityowned-food-garden-a-stunting-prevention-initiative" target="_blank">
                                        {{ $t('sdgs_section.sdg_3_post_4') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Goal 4-->
        <div class="modal fade" id="goal4" tabindex="-1" role="dialog" aria-labelledby="goal4ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-18 font-primary" id="goal4ModalLabel">
                            {{ $t('sdgs_section.sdg_4') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="javascript:void(0)">
                                        {{ $t('sdgs_section.sdg_4_post_1') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://vr4sdgs.org/" target="_blank">
                                        {{ $t('sdgs_section.sdg_4_post_2') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Goal 5-->
        <div class="modal fade" id="goal5" tabindex="-1" role="dialog" aria-labelledby="goal5ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-primary" id="goal5ModalLabel">
                            {{ $t('sdgs_section.sdg_5') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/current-projects/experimenting-for-social-innovation-communitylevel-air-quality-monitoring" target="_blank">
                                        {{ $t('sdgs_section.sdg_5_post_1') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://solutions-catalog.kopernik.info/en/projects/ergonomic-weaving-tool-1" target="_blank">
                                        {{ $t('sdgs_section.sdg_5_post_2') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="javascript:void(0)">
                                        {{ $t('sdgs_section.sdg_5_post_3') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/current-projects/strengthening-womens-economic-empowerment-through-business-development" target="_blank">
                                        {{ $t('sdgs_section.sdg_5_post_4') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Goal 6-->
        <div class="modal fade" id="goal6" tabindex="-1" role="dialog" aria-labelledby="goal6ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-18 font-primary" id="goal6ModalLabel">
                            {{ $t('sdgs_section.sdg_6') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://solutions-catalog.kopernik.info/en/projects/water-desalination-technology" target="_blank">
                                        {{ $t('sdgs_section.sdg_6_post_1') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="javascript:void(0)">
                                        {{ $t('sdgs_section.sdg_6_post_2') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/current-projects/establishing-communityowned-food-garden-a-stunting-prevention-initiative" target="_blank">
                                        {{ $t('sdgs_section.sdg_6_post_3') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="javascript:void(0)">
                                        {{ $t('sdgs_section.sdg_6_post_4') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Goal 7-->
        <div class="modal fade" id="goal7" tabindex="-1" role="dialog" aria-labelledby="goal7ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-18 font-primary" id="goal7ModalLabel">
                            {{ $t('sdgs_section.sdg_7') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="javascript:void(0)">
                                        {{ $t('sdgs_section.sdg_7_post_1') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Goal 8-->
        <div class="modal fade" id="goal8" tabindex="-1" role="dialog" aria-labelledby="goal8ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-18 font-primary" id="goal8ModalLabel">
                            {{ $t('sdgs_section.sdg_8') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/news-events/news/kopernik-partners-with-haka-to-explore-sustainable-livelihood-alternatives-in-lesten-village" target="_blank">
                                        {{ $t('sdgs_section.sdg_8_post_1') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://solutions-catalog.kopernik.info/en/projects/ergonomic-weaving-tool-1" target="_blank">
                                        {{ $t('sdgs_section.sdg_8_post_2') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="javascript:void(0)">
                                        {{ $t('sdgs_section.sdg_8_post_3') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="javascript:void(0)">
                                        {{ $t('sdgs_section.sdg_8_post_4') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/donate/covid19-response-supporting-communities-affected-by-the-covid19-pandemic" target="_blank">
                                        {{ $t('sdgs_section.sdg_8_post_5') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/current-projects/strengthening-womens-economic-empowerment-through-business-development" target="_blank">
                                        {{ $t('sdgs_section.sdg_8_post_6') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="javascript:void(0)">
                                        {{ $t('sdgs_section.sdg_8_post_7') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Goal 9-->
        <div class="modal fade" id="goal9" tabindex="-1" role="dialog" aria-labelledby="goal9ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-18 font-primary" id="goal7ModalLabel">
                            {{ $t('sdgs_section.sdg_9') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/donate/covid19-response-supporting-communities-affected-by-the-covid19-pandemic" target="_blank">
                                        {{ $t('sdgs_section.sdg_9_post_1') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://vr4sdgs.org/" target="_blank">
                                        {{ $t('sdgs_section.sdg_9_post_2') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Goal 10-->
        <div class="modal fade" id="goal10" tabindex="-1" role="dialog" aria-labelledby="goal10ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-18 font-primary" id="goal7ModalLabel">
                            {{ $t('sdgs_section.sdg_10') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://static1.squarespace.com/static/5d94e54cb06c703e5199d288/t/60d24a684f78f942d0da8a9b/1624394378656/Indonesia_Women_Money_English_June2021.pdf" target="_blank">
                                        {{ $t('sdgs_section.sdg_10_post_1') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="javascript:void(0)">
                                        {{ $t('sdgs_section.sdg_10_post_2') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/current-projects/strengthening-womens-economic-empowerment-through-business-development" target="_blank">
                                        {{ $t('sdgs_section.sdg_10_post_3') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="javascript:void(0)">
                                        {{ $t('sdgs_section.sdg_10_post_4') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Goal 11-->
        <div class="modal fade" id="goal11" tabindex="-1" role="dialog" aria-labelledby="goal11ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-18 font-primary" id="goal11ModalLabel">
                            {{ $t('sdgs_section.sdg_11') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/current-projects/experimenting-for-social-innovation-communitylevel-air-quality-monitoring" target="_blank">
                                        {{ $t('sdgs_section.sdg_11_post_1') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/current-projects/improving-waste-sorting-behavior-in-bali" target="_blank">
                                        {{ $t('sdgs_section.sdg_11_post_2') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://solutions-catalog.kopernik.info/en/projects/pulau-plastik-behavior-change-campaign" target="_blank">
                                        {{ $t('sdgs_section.sdg_11_post_3') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Goal 12-->
        <div class="modal fade" id="goal12" tabindex="-1" role="dialog" aria-labelledby="goal12ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-18 font-primary" id="goal12ModalLabel">
                            {{ $t('sdgs_section.sdg_12') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://solutions-catalog.kopernik.info/en/projects/water-desalination-technology" target="_blank">
                                        {{ $t('sdgs_section.sdg_12_post_1') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/current-projects/improving-waste-sorting-behavior-in-bali" target="_blank">
                                        {{ $t('sdgs_section.sdg_12_post_2') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://solutions-catalog.kopernik.info/en/projects/pulau-plastik-behavior-change-campaign" target="_blank">
                                        {{ $t('sdgs_section.sdg_12_post_3') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/donate/covid19-response-supporting-communities-affected-by-the-covid19-pandemic" target="_blank">
                                        {{ $t('sdgs_section.sdg_12_post_4') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Goal 13-->
        <div class="modal fade" id="goal13" tabindex="-1" role="dialog" aria-labelledby="goal13ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-18 font-primary" id="goal13ModalLabel">
                            {{ $t('sdgs_section.sdg_13') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/current-projects/improving-waste-sorting-behavior-in-bali" target="_blank">
                                        {{ $t('sdgs_section.sdg_13_post_1') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://solutions-catalog.kopernik.info/en/projects/pulau-plastik-behavior-change-campaign" target="_blank">
                                        {{ $t('sdgs_section.sdg_13_post_2') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Goal 14-->
        <div class="modal fade" id="goal14" tabindex="-1" role="dialog" aria-labelledby="goal14ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-18 font-primary" id="goal14ModalLabel">
                            {{ $t('sdgs_section.sdg_14') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://solutions-catalog.kopernik.info/en/projects/pulau-plastik-behavior-change-campaign" target="_blank">
                                        {{ $t('sdgs_section.sdg_14_post_1') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Goal 15-->
        <div class="modal fade" id="goal15" tabindex="-1" role="dialog" aria-labelledby="goal15ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-18 font-primary" id="goal15ModalLabel">
                            {{ $t('sdgs_section.sdg_15') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/current-projects/improving-waste-sorting-behavior-in-bali" target="_blank">
                                        {{ $t('sdgs_section.sdg_15_post_1') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="javascript:void(0)">
                                        {{ $t('sdgs_section.sdg_15_post_2') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/news-events/news/kopernik-partners-with-haka-to-explore-sustainable-livelihood-alternatives-in-lesten-village" target="_blank">
                                        {{ $t('sdgs_section.sdg_15_post_3') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://solutions-catalog.kopernik.info/en/projects/pulau-plastik-behavior-change-campaign" target="_blank">
                                        {{ $t('sdgs_section.sdg_15_post_4') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal Goal 17-->
        <div class="modal fade" id="goal17" tabindex="-1" role="dialog" aria-labelledby="goal17ModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title font-18 font-primary" id="goal17ModalLabel">
                            {{ $t('sdgs_section.sdg_17') }}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <ul>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://vr4sdgs.org/" target="_blank">
                                        {{ $t('sdgs_section.sdg_17_post_1') }}
                                    </a>
                                </p>
                            </li>
                            <li>
                                <div class="left">
                                    <img src="@/assets/logos/chevron-right-icon-36x36.png" class="icon-kopernik">
                                </div>
                                <p class="pl-3 ml-lg-3 text-left">
                                    <a href="https://kopernik.info/en/donate/covid19-response-supporting-communities-affected-by-the-covid19-pandemic" target="_blank">
                                        {{ $t('sdgs_section.sdg_17_post_2') }}
                                    </a>
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- SDG's ends -->
</template>

<script>
export default {
    name: 'SDGs-Section'
}
</script>