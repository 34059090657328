<template>
    <div>
        <!-- Main Section start -->
        <section id="home" class="p-0 no-transition h-100vh">
            <h2 class="d-none">heading</h2>
            <!--Main Slider-->
            <div id="revo_thumbs_wrapper" class="rev_slider_wrapper fullwidthbanner-container">
                <div id="secondary-banner" class="rev_slider fullwidthabanner" style="display:none;" data-version="5.4.1">
                    <ul>
                        <!-- SLIDE TITLE -->
                        <li data-index="rs-04" data-transition="fade" data-slotamount="default" data-easein="Power3.easeInOut" data-easeout="Power3.easeInOut" data-masterspeed="2000" data-fsmasterspeed="1500" data-param1="">
                            <!-- MAIN IMAGE -->
                            <img src="@/assets/img/compressed/cover_images/hero-title-resize-1248x832.jpg" alt="" data-bgposition="center center" data-bgfit="cover" data-bgrepeat="no-repeat" class="rev-slidebg" data-bgparallax="0" data-no-retina>
                            <div class="bg-overlay bg-black opacity-5"></div>
                            <!-- LAYER NR. 1 -->
                            <!--<div class="tp-caption tp-resizeme switch-mobile"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['-50','-70','-70','-55']"
                                data-fontsize="['35','55','55','35']"
                                data-whitespace="nowrap" data-responsive_offset="on"
                                data-width="['none','none','none','none']" data-type="text"
                                data-textalign="['center','center','center','center']"
                                data-frames='[{"delay":1000,"speed":1500,"frame":"0","from":"x:50px;opacity:0;","to":"o:1;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"auto:auto;","ease":"Power3.easeInOut"}]'
                                data-start="1500" data-splitin="none" data-splitout="none"
                                style="z-index:1; font-weight: 500; line-height: 60px; color: #ffffff;font-family: 'FuturaStd-Bold', sans-serif;text-transform:capitalize;font-size:22px;">{{ $t('hero_image_title') }}
                            </div>-->

                            <!-- LAYER NR. 2 -->
                            <div class="tp-caption tp-resizeme switch-mobile"
                                data-x="['center','center','center','center']" data-hoffset="['0','0','0','0']"
                                data-y="['middle','middle','middle','middle']" data-voffset="['5','5','5','15']"
                                data-fontsize="['65','55','55','35']"
                                data-whitespace="nowrap" data-responsive_offset="on"
                                data-width="['none','none','none','none']" data-type="text"
                                data-textalign="['center','center','center','center']"
                                data-frames='[{"delay":1500,"speed":1500,"frame":"0","from":"x:[100%];opacity:1;","mask":"x:[-100%];y:0;s:inherit;e:inherit;","to":"o:1;","ease":"Power4.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"x:[100%];opacity:1;","mask":"x:[-100%];y:0;s:inherit;e:inherit;","ease":"Power4.easeInOut"}]'
                                data-start="2000" data-splitin="none" data-splitout="none"
                                style="z-index:1; font-weight: 800; line-height: 100px; color: #ffffff;font-family: 'FuturaStd-Bold', sans-serif;text-transform:normal">{{ $t('hero_image_subs') }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <!--Main Slider ends -->
        </section>
        <!-- Main Section end -->

        <!-- About start -->
        <section id="experimenting" class="bg-kopernik2">
            <div class="container">
                <div class="row">
                    <div class="container">
                        <div class="col-sm-12 mb-xs-2rem text-center wow fadeIn" data-wow-delay="300ms">
                            <!--<h2 class="my-sm-4 font-45 font-secondary font-weight-bold">
                                {{ $t('about_section.title') }}
                            </h2>-->
                            <div class="content-editor" v-html="$t('about_section.content')"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- About ends -->

        <Work />

        <Category />

        <Comms />

        <Team />

        <SDGS />

        <Finance />

        <Partners />

        <PreClose />
    </div>
</template>

<script>
import Work from './Sections/WorkSection'
import Category from './Sections/CategorySection'
import Comms from './Sections/CommsSection'
import Finance from './Sections/FinanceSection'
import Partners from './Sections/PartnersSection'
import PreClose from './Sections/CloseSection'
import Team from './Sections/TeamSection'
import SDGS from './Sections/SDGSection'
export default {
    name: 'Home',
    components: {
        Work,
        Category,
        Comms,
        Finance,
        Partners,
        PreClose,
        Team,
        SDGS
    },
    mounted() {
        if(localStorage.getItem('reloaded')) {
            localStorage.removeItem('reloaded');
        }
        else {
            localStorage.setItem('reloaded', '1');
            location.reload();
        }
    },
    
}
</script>