<template>
    <!-- Work -->
    <section class="work">
        <div class="container-fluid pt-4rem">
            <div class="row">
                <div class="col-lg-3">
                    <div class="card bg-white text-white communications">
                        <div class="item">
                            <div class="team-data-img wow fadeInLeft" data-wow-delay="350ms">
                                <div class="image" @mouseover="digitalInitiatives = true">
                                    <a href="#leanexperimentation" class="scroll">
                                        <img src="@/assets/img/compressed/illustration/1-finding-solutions.png" :alt="$t('work_section.lean_solutions')" class="img-responsive" data-no-retina>
                                        <p class="bottom-centered-thumb color-black">
                                            {{ $t('work_section.lean_solutions') }}
                                        </p>
                                        <div class="team-overlay center-block">
                                            <ul class="d-flex justify-content-center noborder">
                                                <li>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <span class="ml-md-2 kopernik">
                                                        <i class="fa fa-arrow-down fa-2x"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card bg-white text-white communications">
                        <div class="item">
                            <div class="team-data-img wow fadeInLeft" data-wow-delay="350ms">
                                <div class="image" @mouseover="mediaCoverage = true">
                                    <router-link :to="{name: 'Team-project-1'}">
                                        <img src="@/assets/img/compressed/illustration/2-next-ceo.png" :alt="$t('work_section.next_ceo')" class="img-responsive" data-no-retina>
                                        <p class="bottom-centered-thumb color-black">
                                            {{ $t('work_section.next_ceo') }}
                                        </p>
                                        <div class="team-overlay center-block">
                                            <ul class="d-flex justify-content-center noborder">
                                                <li>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <span class="ml-md-2 kopernik">
                                                        <i class="fa fa-arrow-down fa-2x"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card bg-white text-white communications">
                        <div class="item">
                            <div class="team-data-img wow fadeInRight" data-wow-delay="350ms">
                                <div class="image" @mouseover="events = true">
                                    <router-link :to="{name: 'Team-project-2'}">
                                        <img src="@/assets/img/compressed/illustration/3-wellbeing-program.png" :alt="$t('work_section.wellbeing_support')" class="img-responsive" data-no-retina>
                                        <br>
                                        <p class="bottom-centered-thumb color-black">
                                            {{ $t('work_section.wellbeing_support') }}
                                        </p>
                                        <div class="team-overlay center-block">
                                            <ul class="d-flex justify-content-center noborder">
                                                <li>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <span class="ml-md-2 kopernik">
                                                        <i class="fa fa-arrow-down fa-2x"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card bg-white text-white communications">
                        <div class="item">
                            <div class="team-data-img wow fadeInRight" data-wow-delay="350ms">
                                <div class="image" @mouseover="digitalOutreach = true">
                                    <a href="#communications-and-community" class="scroll">
                                        <img src="@/assets/img/compressed/illustration/4-digital-media-initiatives.png" :alt="$t('work_section.digital_initiatives')" class="img-responsive" data-no-retina>
                                        <br>
                                        <p class="bottom-centered-thumb color-black">
                                            {{ $t('work_section.digital_initiatives') }}
                                        </p>
                                        <div class="team-overlay center-block">
                                            <ul class="d-flex justify-content-center noborder">
                                                <li>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <br>
                                                    <span class="ml-md-2 kopernik">
                                                        <i class="fa fa-arrow-down fa-2x"></i>
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "Work-Section"
}
</script>