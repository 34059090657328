/* Define route */
import { createRouter, createWebHistory } from 'vue-router'
import { setI18nLanguage, loadLocaleMessages, SUPPORT_LOCALES } from './i18n'

/*
* Define pages 
*/
import HomeLayout from './components/layouts/HomeLayout.vue'
import Home from './views/Home.vue'
/* Lean Experimentation*/
import LeanLayout from './components/layouts/LeanLayout.vue'
import Lean1 from './views/LeanExperimentation/Project-1.vue'
import Lean2 from './views/LeanExperimentation/Project-2.vue'
import Lean3 from './views/LeanExperimentation/Project-3.vue'
import Lean4 from './views/LeanExperimentation/Project-4.vue'
import Lean5 from './views/LeanExperimentation/Project-5.vue'
import Lean6 from './views/LeanExperimentation/Project-6.vue'
import Lean7 from './views/LeanExperimentation/Project-7.vue'
import Lean8 from './views/LeanExperimentation/Project-8.vue'
/* Solution in Action */
import SolutionLayout from './components/layouts/SolutionLayout.vue'
import Solution1 from './views/SolutionInAction/Project-1.vue'
import Solution2 from './views/SolutionInAction/Project-2.vue'
import Solution3 from './views/SolutionInAction/Project-3.vue'
import Solution4 from './views/SolutionInAction/Project-4.vue'
import Solution5 from './views/SolutionInAction/Project-5.vue'
import Solution6 from './views/SolutionInAction/Project-6.vue'
/* Digital Initiatives */
import DigitalInitLayout from './components/layouts/DigitalInitiatives.vue'
import DigitalInit1 from './views/DigitalInitiatives/Project-1.vue'
import DigitalInit2 from './views/DigitalInitiatives/Project-2.vue'
import DigitalInit3 from './views/DigitalInitiatives/Project-3.vue'
/* Kopernik Team */
import TeamLayout from './components/layouts/KopernikTeam.vue'
import Team1 from './views/KopernikTeam/Project-1.vue'
import Team2 from './views/KopernikTeam/Project-2.vue'

/* Init route */
export function setupRouter(i18n) {
    const locale =
    i18n.mode === 'legacy' ? i18n.global.locale : i18n.global.locale.value

    // setup routes
    const routes = [
        {
            path: '/:locale/',
            name: 'Main-Layout',
            component: HomeLayout,
            children: [
                {
                    path: '/:locale/',
                    name: 'Home',
                    meta: {
                        topdefault: true,
                        footer: true,
                        transitionName: 'fade'
                    },
                    component: Home
                },
                {
                    path: 'lean-experimentation',
                    name: 'Lean-Experimentation',
                    component: LeanLayout,
                    meta: {
                        transitionName: 'slide'
                    },
                    children: [
                        {
                            path: 'project-1',
                            name: 'Lean-project-1',
                            component: Lean1,
                            meta: {transitionName: 'slide'},
                        },
                        {
                            path: 'project-2',
                            name: 'Lean-project-2',
                            component: Lean2,
                            meta: {transitionName: 'slide'}
                        },
                        {
                            path: 'project-3',
                            name: 'Lean-project-3',
                            component: Lean3,
                            meta: {transitionName: 'slide'}
                        },
                        {
                            path: 'project-4',
                            name: 'Lean-project-4',
                            component: Lean4,
                            meta: {transitionName: 'slide'}
                        },
                        {
                            path: 'project-5',
                            name: 'Lean-project-5',
                            component: Lean5,
                            meta: {transitionName: 'slide'}
                        },
                        {
                            path: 'project-6',
                            name: 'Lean-project-6',
                            component: Lean6,
                            meta: {transitionName: 'slide'}
                        },
                        {
                            path: 'project-7',
                            name: 'Lean-project-7',
                            component: Lean7,
                            meta: {transitionName: 'slide'}
                        },
                        {
                            path: 'project-8',
                            name: 'Lean-project-8',
                            component: Lean8,
                            meta: {transitionName: 'slide'}
                        }
                    ]
                },
                {
                    path: 'solution-in-action',
                    name: 'Solution-in-action',
                    component: SolutionLayout,
                    meta: {
                        transitionName: 'slide'
                    },
                    children: [
                        {
                            path: 'project-1',
                            name: 'Solution-project-1',
                            component: Solution1,
                            meta: {transitionName: 'slide'},
                        },
                        {
                            path: 'project-2',
                            name: 'Solution-project-2',
                            component: Solution2,
                            meta: {transitionName: 'slide'}
                        },
                        {
                            path: 'project-3',
                            name: 'Solution-project-3',
                            component: Solution3,
                            meta: {transitionName: 'slide'}
                        },
                        {
                            path: 'project-4',
                            name: 'Solution-project-4',
                            component: Solution4,
                            meta: {transitionName: 'slide'}
                        },
                        {
                            path: 'project-5',
                            name: 'Solution-project-5',
                            component: Solution5,
                            meta: {transitionName: 'slide'}
                        },
                        {
                            path: 'project-6',
                            name: 'Solution-project-6',
                            component: Solution6,
                            meta: {transitionName: 'slide'}
                        }
                    ]
                },
                {
                    path: 'digital-initiatives',
                    name: 'digital-initiatives',
                    component: DigitalInitLayout,
                    meta: {
                        transitionName: 'slide'
                    },
                    children: [
                        {
                            path: 'project-1',
                            name: 'Digital-project-1',
                            component: DigitalInit1,
                            meta: {transitionName: 'slide'},
                        },
                        {
                            path: 'project-2',
                            name: 'Digital-project-2',
                            component: DigitalInit2,
                            meta: {transitionName: 'slide'}
                        },
                        {
                            path: 'project-3',
                            name: 'Digital-project-3',
                            component: DigitalInit3,
                            meta: {transitionName: 'slide'}
                        }
                    ]
                },
                {
                    path: 'kopernik-team',
                    name: 'kopernik-team',
                    component: TeamLayout,
                    meta: {
                        transitionName: 'slide'
                    },
                    children: [
                        {
                            path: 'next-ceo',
                            name: 'Team-project-1',
                            component: Team1,
                            meta: {transitionName: 'slide'},
                        },
                        {
                            path: 'parental-leave',
                            name: 'Team-project-2',
                            component: Team2,
                            meta: {transitionName: 'slide'}
                        }
                    ]
                }
            ]
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: () => `/${locale}`
        }
    ]

    const router = createRouter({
        history: createWebHistory(),
        routes
    })

    router.beforeEach(async to => {
        const paramsLocale = to.params.locale

        if (!SUPPORT_LOCALES.includes(paramsLocale)) {
            return `/${locale}`
        }

        if (!i18n.global.availableLocales.includes(paramsLocale)) {
            await loadLocaleMessages(i18n, paramsLocale)
        }

        setI18nLanguage(i18n, paramsLocale)
    })

  return router
}