<template>
    <section class="team">
        <div id="team" class="count-digital-sec">
            <div class="count-digital-overlay"></div>
            <div class="container">
                <div class="row text-center count-digital-content">
                    <div class="col-12 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('team_section.title') }}
                        </h2>
                        <p class="text-des">
                            {{ $t('team_section.content') }}
                        </p>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" id="digital-post">
            <div class="row">
                <div class="col-sm-12">
                    <div class="category owl-team owl-carousel text-center wow fadeInUp">
                        <div class="item">
                            <div class="team-data-img wow fadeInUp" data-wow-delay="450ms">
                                <div class="image">
                                    <router-link :to="{name: 'Team-project-1'}" class="scroll text-white">
                                        <img src="@/assets/img/compressed/kopernik_team/THE-NEXT-CEO/NEXT-CEO-650x340.jpg" :alt="$t('team_section.team_title_1')">
                                        <h5 class="centered-thumb color-white font-weight-700 mb-2 text-capitalize font-secondary">
                                            {{ $t('team_section.team_title_1') }}
                                        </h5>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="team-data-img wow fadeInUp" data-wow-delay="450ms">
                                <div class="image">
                                    <router-link :to="{name: 'Team-project-2'}" class="scroll text-white">
                                        <img src="@/assets/img/compressed/kopernik_team/PAID-PARENTAL-LEAVE/PARENTAL-LEAVE-650x340.jpg" :alt="$t('team_section.team_title_2')">
                                        <h5 class="centered-thumb color-white font-weight-700 mb-2 text-capitalize font-secondary">
                                            {{ $t('team_section.team_title_2') }}
                                        </h5>
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Kopernik Team ends -->
</template>

<script>
export default {
    name: "Team-Section"
}
</script>