<template>
    <!-- Detail Project starts -->
    <section id="blog" class="details-project bg-light-gray text-left" @keydown.esc="backMethods">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <!-- Project List Inner -->
                    <div class="blog-listing-inner heading-space-half">
                        <div class="image">
                            <img src="@/assets/img/compressed/kopernik_team/THE-NEXT-CEO/NEXT-CEO-1110x450.jpg" :alt="$t('team_section.team_title_1')">
                        </div>
                    </div>
                </div>
                <!-- Project Detail Listing -->
                <div class="col-lg-12">
                    <div class="blog-box heading-space-half m-md-0">
                        <!-- Project List Inner -->
                        <div class="blog-listing-inner news_item wow fadeIn" data-wow-delay="300ms">
                            <div class="news_desc">
                                <h3 class="pb-4 font-weight-normal font-primary text-uppercase" v-html="$t('team_section.team_title_1_content')">
                                </h3>
                                <div class="content-editor" v-html="$t('team_section.team_title_1_subs')"></div>
                            </div>
                            <!-- Button Close -->
                            <div class="wow fadeIn" data-wow-delay="600ms">
                                <span class="close-btn-bottom2 kopernik">
                                    <a href="parental-leave" class="color-grey">
                                        <i class="fa fa-arrow-right fa-2x"></i>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Detail Project ends -->
</template>

<script>
export default {
    name: 'Project 1',
    methods: {
        backMethods() {
            this.$router.go(-1)
        }
    }
}
</script>