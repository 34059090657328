<template>
    <!-- Lean Experimentating start -->
    <section class="category">
        <div id="leanexperimentation" class="count-sec">
            <div class="count-overlay"></div>
            <div class="container">
                <div class="row text-center count-content">
                    <div class="col-12 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('category_section.category_title_1') }}
                        </h2>
                        <p class="text-des">
                           {{ $t('category_section.category_title_1_subs') }}
                        </p>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="portfolio-sec" id="portfolio-sec">
            <div id="js-filters-full-width" class="cbp-l-filters-alignCenter">
                <div data-filter="*" class="cbp-filter-item-active cbp-filter-item rounded-pill">
                    {{ $t('category_lean.category_all') }}
                    <div class="cbp-filter-counter"></div>
                </div>
                <div data-filter=".prototyping" class="cbp-filter-item rounded-pill">
                    {{ $t('category_lean.category_prototyping') }}
                    <div class="cbp-filter-counter"></div>
                </div>
                <div data-filter=".partnership" class="cbp-filter-item rounded-pill">
                    {{ $t('category_lean.category_partnership') }}
                    <div class="cbp-filter-counter"></div>
                </div>
                <div data-filter=".research" class="cbp-filter-item rounded-pill">
                    {{ $t('category_lean.category_research') }}
                    <div class="cbp-filter-counter"></div>
                </div>
            </div>

            <!-- List Container - Lean Experimentation -->
            <div id="js-grid-full-width" class="cbp">
                <div class="cbp-item prototyping">
                    <router-link :to="{name: 'Lean-project-1'}" class="cbp-caption" :data-title="$t('category_lean.project_1_title')">
                        <div class="cbp-caption-defaultWrap">
                            <img src="@/assets/img/compressed/lean_experimentation/Aquaponics-650x340.jpg" :alt="$t('category_lean.project_1_title')">
                        </div>
                        <div class="cbp-caption-activeWrap">
                            <div class="cbp-l-caption-alignCenter">
                                <div class="cbp-l-caption-body">
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_lean.project_1_title') }}
                                    </div>
                                    <div class="cbp-l-caption-desc"><span class="fly-line"></span></div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="cbp-item prototyping">
                    <router-link :to="{name: 'Lean-project-2'}" class="cbp-caption" :data-title="$t('category_lean.project_2_title')">
                        <div class="cbp-caption-defaultWrap">
                            <img src="@/assets/img/compressed/lean_experimentation/Waste-for-Water-650x340.jpg" :alt="$t('category_lean.project_2_title')">
                        </div>
                        <div class="cbp-caption-activeWrap">
                            <div class="cbp-l-caption-alignCenter">
                                <div class="cbp-l-caption-body">
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_lean.project_2_title_1') }}
                                    </div>
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_lean.project_2_title_2') }}
                                    </div>
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_lean.project_2_title_3') }}
                                    </div>
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_lean.project_2_title_4') }}
                                    </div>
                                    <div class="cbp-l-caption-desc"><span class="fly-line"></span></div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="cbp-item prototyping">
                    <router-link :to="{name: 'Lean-project-3'}" class="cbp-caption" :data-title="$t('category_lean.project_3_title')">
                        <div class="cbp-caption-defaultWrap">
                            <img src="@/assets/img/compressed/lean_experimentation/Ergonomic-Weaving-Tools-650x340.jpg" :alt="$t('category_lean.project_3_title')">
                        </div>
                        <div class="cbp-caption-activeWrap">
                            <div class="cbp-l-caption-alignCenter">
                                <div class="cbp-l-caption-body">
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_lean.project_3_title') }}
                                    </div>
                                    <div class="cbp-l-caption-desc"><span class="fly-line"></span></div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="cbp-item prototyping">
                    <router-link :to="{name: 'Lean-project-4'}" class="cbp-caption" :data-title="$t('category_lean.project_4_title')">
                        <div class="cbp-caption-defaultWrap">
                            <img src="@/assets/img/compressed/lean_experimentation/Waste-Sorting-650x340.jpg" :alt="$t('category_lean.project_4_title')">
                        </div>
                        <div class="cbp-caption-activeWrap">
                            <div class="cbp-l-caption-alignCenter">
                                <div class="cbp-l-caption-body">
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_lean.project_4_title') }}
                                    </div>
                                    <div class="cbp-l-caption-desc"><span class="fly-line"></span></div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="cbp-item research partnership">
                    <router-link :to="{name: 'Lean-project-5'}" class="cbp-caption" :data-title="$t('category_lean.project_5_title')">
                        <div class="cbp-caption-defaultWrap">
                            <img src="@/assets/img/compressed/lean_experimentation/IDEO-650x340.jpg" :alt="$t('category_lean.project_5_title')">
                        </div>
                        <div class="cbp-caption-activeWrap">
                            <div class="cbp-l-caption-alignCenter">
                                <div class="cbp-l-caption-body">
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_lean.project_5_title') }}
                                    </div>
                                    <div class="cbp-l-caption-desc"><span class="fly-line"></span></div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="cbp-item prototyping partnership">
                    <router-link :to="{name: 'Lean-project-6'}" class="cbp-caption" :data-title="$t('category_lean.project_6_title')">
                        <div class="cbp-caption-defaultWrap">
                            <img src="@/assets/img/compressed/lean_experimentation/Rainwater-Harvesting-Tanks-650x340.jpg" :alt="$t('category_lean.project_6_title')">
                        </div>
                        <div class="cbp-caption-activeWrap">
                            <div class="cbp-l-caption-alignCenter">
                                <div class="cbp-l-caption-body">
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_lean.project_6_title') }}
                                    </div>
                                    <div class="cbp-l-caption-desc"><span class="fly-line"></span></div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>                
                <div class="cbp-item research">
                    <router-link :to="{name: 'Lean-project-7'}" class="cbp-caption" :data-title="$t('category_lean.project_7_title')">
                        <div class="cbp-caption-defaultWrap">
                            <img src="@/assets/img/compressed/lean_experimentation/HAkA-1300x340.jpg" :alt="$t('category_lean.project_7_title')">
                        </div>
                        <div class="cbp-caption-activeWrap">
                            <div class="cbp-l-caption-alignCenter">
                                <div class="cbp-l-caption-body">
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_lean.project_7_title') }}
                                    </div>
                                    <div class="cbp-l-caption-desc"><span class="fly-line"></span></div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="cbp-item prototyping">
                    <router-link :to="{name: 'Lean-project-8'}" class="cbp-caption" :data-title="$t('category_lean.project_8_title')">
                        <div class="cbp-caption-defaultWrap">
                            <img src="@/assets/img/compressed/lean_experimentation/Stunting-Prevention-650x340.jpg" :alt="$t('category_lean.project_8_title')">
                        </div>
                        <div class="cbp-caption-activeWrap">
                            <div class="cbp-l-caption-alignCenter">
                                <div class="cbp-l-caption-body">
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_lean.project_8_title') }}
                                    </div>
                                    <div class="cbp-l-caption-desc"><span class="fly-line"></span></div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
    <!-- Lean Experimentating ends -->

    <!-- Solutions In Action start -->
    <section class="category">
        <div id="solutionsinaction" class="count-sec">
            <div class="count-overlay"></div>
            <div class="container">
                <div class="row text-center count-content">
                    <div class="col-12 wow fadeIn" data-wow-delay="300ms">
                        <h2 class="font-primary text-white">
                            {{ $t('category_section.category_title_2') }}
                        </h2>
                        <p class="text-des">
                            {{ $t('category_section.category_title_2_subs') }}
                        </p>
                        <span class="fly-line"></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="portfolio-sec" id="portfolio-sec">
            <div id="js-filters-full-width-2" class="cbp-l-filters-alignCenter">
                <div data-filter="*" class="cbp-filter-item-active cbp-filter-item rounded-pill">
                    {{ $t('category_solution.category_all') }}
                    <div class="cbp-filter-counter"></div>
                </div>
                <div data-filter=".technology" class="cbp-filter-item rounded-pill">
                    {{ $t('category_solution.category_distribution') }}
                    <div class="cbp-filter-counter"></div>
                </div>
                <div data-filter=".disaster" class="cbp-filter-item rounded-pill">
                    {{ $t('category_solution.category_response') }}
                    <div class="cbp-filter-counter"></div>
                </div>
                <div data-filter=".capacity" class="cbp-filter-item rounded-pill">
                    {{ $t('category_solution.category_development') }}
                    <div class="cbp-filter-counter"></div>
                </div>
            </div>

            <!-- List Container - Solutions In Action -->
            <div id="js-grid-full-width-2" class="cbp">
                <div class="cbp-item technology disaster capacity">
                    <router-link :to="{name: 'Solution-project-1'}" class="cbp-caption" :data-title="$t('category_solution.project_1_1_title')+' '+$t('category_solution.project_1_2_title')">
                        <div class="cbp-caption-defaultWrap">
                            <img src="@/assets/img/compressed/solution_in_action/COVID-19-Response-650x340.jpg" :alt="$t('category_solution.project_1_1_title')+' '+$t('category_solution.project_1_2_title')">
                        </div>
                        <div class="cbp-caption-activeWrap">
                            <div class="cbp-l-caption-alignCenter">
                                <div class="cbp-l-caption-body">
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_solution.project_1_1_title') }}
                                    </div>
                                    <div class="cbp-l-caption-desc"><span class="fly-line"></span></div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="cbp-item capacity">
                    <router-link :to="{name: 'Solution-project-2'}" class="cbp-caption" :data-title="$t('category_solution.project_2_title_1')">
                        <div class="cbp-caption-defaultWrap">
                            <img src="@/assets/img/compressed/solution_in_action/COVID-19-Livelihood-650x340.jpg" :alt="$t('category_solution.project_2_title_1')">
                        </div>
                        <div class="cbp-caption-activeWrap">
                            <div class="cbp-l-caption-alignCenter">
                                <div class="cbp-l-caption-body">
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_solution.project_2_title_1') }}
                                    </div>
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_solution.project_2_title_2') }}
                                    </div>
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_solution.project_2_title_3') }}
                                    </div>
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_solution.project_2_title_4') }}
                                    </div>
                                    <div class="cbp-l-caption-desc"><span class="fly-line"></span></div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="cbp-item capacity">
                    <router-link :to="{name: 'Solution-project-3'}" class="cbp-caption" :data-title="$t('category_solution.project_3_1_title')+' '+$t('category_solution.project_3_2_title')+' '+$t('category_solution.project_3_3_title')">
                        <div class="cbp-caption-defaultWrap">
                            <img src="@/assets/img/compressed/solution_in_action/Pulau-Plastik-650x340.jpg" :alt="$t('category_solution.project_3_1_title')+' '+$t('category_solution.project_3_2_title')+' '+$t('category_solution.project_3_3_title')">
                        </div>
                        <div class="cbp-caption-activeWrap">
                            <div class="cbp-l-caption-alignCenter">
                                <div class="cbp-l-caption-body">
                                    <div class="cbp-l-caption-title font-weight-bold" style="font-size: 16px;">
                                        {{ $t('category_solution.project_3_1_title') }}
                                        <br>
                                        {{ $t('category_solution.project_3_2_title') }}
                                        <br>
                                        {{ $t('category_solution.project_3_3_title') }}
                                    </div>
                                    <div class="cbp-l-caption-desc"><span class="fly-line"></span></div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="cbp-item capacity">
                    <router-link :to="{name: 'Solution-project-4'}" class="cbp-caption" :data-title="$t('category_solution.project_4_title')">
                        <div class="cbp-caption-defaultWrap">
                            <img src="@/assets/img/compressed/solution_in_action/Wonder-Women-650x340.jpg" :alt="$t('category_solution.project_4_title')">
                        </div>
                        <div class="cbp-caption-activeWrap">
                            <div class="cbp-l-caption-alignCenter">
                                <div class="cbp-l-caption-body">
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_solution.project_4_title') }}
                                    </div>
                                    <div class="cbp-l-caption-desc"><span class="fly-line"></span></div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="cbp-item capacity">
                    <router-link :to="{name: 'Solution-project-5'}" class="cbp-caption" :data-title="$t('category_solution.project_5_title')">
                        <div class="cbp-caption-defaultWrap">
                            <img src="@/assets/img/compressed/solution_in_action/Youth-for-Papua-650x340.jpg" :alt="$t('category_solution.project_5_title')">
                        </div>
                        <div class="cbp-caption-activeWrap">
                            <div class="cbp-l-caption-alignCenter">
                                <div class="cbp-l-caption-body">
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_solution.project_5_title') }}
                                    </div>
                                    <div class="cbp-l-caption-desc"><span class="fly-line"></span></div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="cbp-item technology disaster">
                    <router-link :to="{name: 'Solution-project-6'}" class="cbp-caption" :data-title="$t('category_solution.project_6_title')">
                        <div class="cbp-caption-defaultWrap">
                            <img src="@/assets/img/compressed/solution_in_action/Emergency-Response-650x340.jpg" :alt="$t('category_solution.project_6_title')">
                        </div>
                        <div class="cbp-caption-activeWrap">
                            <div class="cbp-l-caption-alignCenter">
                                <div class="cbp-l-caption-body">
                                    <div class="cbp-l-caption-title font-weight-bold">
                                        {{ $t('category_solution.project_6_title') }}
                                    </div>
                                    <div class="cbp-l-caption-desc"><span class="fly-line"></span></div>
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </section>
    <!-- Solutions In Action ends -->
</template>

<script>
export default {
    name: 'Category-Section'
}
</script>